<template>
  <div class="ds-container">
    <div class="ds-header">
      <div class="ds-title">
        Configurações
      </div>
    </div>

    <div class="card">
      <div class="card-title">Configurações do módulo estoque</div>
      <div class="option">
        <toggle-button :disabled="!canEditModule" class="togle-button" v-model="form.autocomplete_purchase_price" :sync="true" :height="32" :color="{checked: '#00C773', unchecked: '#E1E5F4'}" @change="(el) => updateWarehouseConfigurations({ autocomplete_purchase_price: el.value })" />
        Preencher automaticamente custos de item e valor ao efetuar saída
      </div>
      <div class="option">
        <toggle-button :disabled="!canEditModule" class="togle-button" v-model="form.entries_outputs_editable" :sync="true" :height="32" :color="{checked: '#00C773', unchecked: '#E1E5F4'}" @change="(el) => updateWarehouseConfigurations({ entries_outputs_editable: el.value })" />
        Permitir edição de saídas e entradas efetuadas
      </div>
      <div class="option">
        <toggle-button :disabled="!canEditModule" class="togle-button" v-model="form.provider_required" :sync="true" :height="32" :color="{checked: '#00C773', unchecked: '#E1E5F4'}" @change="(el) => updateWarehouseConfigurations({ provider_required: el.value })" />
        Preenchimento de fornecedores obrigatório para todos os produtos
      </div>
      <!-- <div class="option">
        <toggle-button class="togle-button" v-model="form.batch_control_required" :sync="true" :height="32" :color="{checked: '#00C773', unchecked: '#E1E5F4'}" @change="(el) => updateWarehouseConfigurations({ batch_control_required: el.value })" />
        Controle de lotes obrigatório para todos os produtos
      </div> -->
    </div>
    <div class="card">
      <div class="card-title">Configurações de aviso</div>
      <div class="option">
        <toggle-button :disabled="!canEditPreferences" class="togle-button" v-model="form.notify_low_stock" :sync="true" :height="32" :color="{checked: '#00C773', unchecked: '#E1E5F4'}" @change="(el) => updateWarehouseConfigurations({ notify_low_stock: el.value })" />
        Permitir avisos do sistema para produtos abaixo da quantidade mínima
      </div>
      <div class="option">
        <toggle-button :disabled="!canEditPreferences" class="togle-button" v-model="form.notify_near_expiring" :sync="true" :height="32" :color="{checked: '#00C773', unchecked: '#E1E5F4'}" @change="(el) => updateWarehouseConfigurations({ notify_near_expiring: el.value })" />
        Permitir avisos do sistema para produtos com vencimento próximo
      </div>
      <div class="option">
        <toggle-button :disabled="!canEditPreferences" class="togle-button" v-model="form.notify_expired_date" :sync="true" :height="32" :color="{checked: '#00C773', unchecked: '#E1E5F4'}" @change="(el) => updateWarehouseConfigurations({ notify_expired_date: el.value })" />
        Permitir avisos do sistema para produtos vencidos
      </div>
    </div>
  </div>
</template>
<script>
import { userHasPermission, getCurrentClinic } from '@/utils/localStorageManager'
export default {
  mounted() {
    this.getWarehouseConfigurations();
  },
  data() {
    return {
      canEditModule: userHasPermission('EstConf2'),
      canEditPreferences: userHasPermission('EstConf3'),
      clinic: getCurrentClinic(),
      form: {},
    }
  },
  methods: {
    getWarehouseConfigurations() {
      const isLoading = this.$loading.show();
      this.api.getWarehouseConfigurations(this.clinic.id)
      .then(res => {
        this.form = res.data;
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
    updateWarehouseConfigurations(props) {
      console.log(props);
      this.api.updateWarehouseConfigurations(this.form.id, props)
      .then(() => {
        this.$toast.success('Configuração atualizada com sucesso.');
      })
      .catch(err => this.$toast.error(err.message));
    },
  }
}
</script>
<style lang="scss" scoped>
.ds-container {
    padding: 30px 10px;
    .ds-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
    }
    .ds-title {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }
}
.card {
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  padding: 20px;
  text-align: left;
  margin: 20px 0;
}
.card-title {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
  color: var(--dark-blue);
}
.option {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.togle-button {
  margin-right: 15px;
}
</style>
